li {
    list-style: none;
}
h2 {
    color: #004d40;
}
h3 {
    color: #ff0000;
    font-weight: 900;
}
