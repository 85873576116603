h3 {
    align-items: center;
    padding-top: 10px;
}

.ConnectCardText {
    padding-top: 40px;
    line-height:300%;
}

#action {
    width: 65px;
    height: 50px;
    padding-left: 15px;
}

.LoginButton {
    padding-top: 172px;
}
