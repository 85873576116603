h1 {
    color: white;
    height: 200px;
    padding-top: 70px;
}

#TopBannerLogo {
    width: 82px;
    height: 62px;
    padding-right: 20px;
}
